/**
 * NOT a default file
 * Restores scroll (scroll to top)
 * Implements a new version using RR Hooks
 * https://reactrouter.com/web/guides/scroll-restoration
 * 
 * Legacy ref links:
 *  https://reacttraining.com/react-router/web/guides/scroll-restoration
 *  https://github.com/ReactTraining/react-router/issues/2019
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop;