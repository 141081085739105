/**
 * ***UPDATE*** new location for staticy content
 * which is content with infrequent changes and 
 * usually does NOT require auth
 * 
 * I have created StaticRoutes for static(y) marketing 
 * I previously had these routes as only static content ENTIRELY in the frontend, NOT in db
 * 
 * for dev purposes, until I came up with this solution
 * these static pages were in privateRoutes with permissions removed
 * that didn't make much sense
 * 
 */

const staticRoutes = [
  
  {
    path: '/policy/:id',
    loader: () =>
      import('src/view/policy/view/PolicyViewPage'),
    exact: true,
  },
  
];

export default staticRoutes;