import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import plan from 'src/modules/plan/planReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';

import state from 'src/modules/state/stateReducers';
import userVehicle from 'src/modules/userVehicle/userVehicleReducers';

import page from 'src/modules/page/pageReducers';
import policy from 'src/modules/policy/policyReducers';
import vehicleRecord from 'src/modules/vehicleRecord/vehicleRecordReducers';
import year from 'src/modules/year/yearReducers';
import make from 'src/modules/make/makeReducers';
import model from 'src/modules/model/modelReducers';
import trim from 'src/modules/trim/trimReducers';
import engine from 'src/modules/engine/engineReducers';

import searchHistory from 'src/modules/searchHistory/searchHistoryReducers';

import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    
    state,
    userVehicle,
    
    page,
    policy,
    vehicleRecord,
    year,
    make,
    model,
    trim,
    engine,
    
    searchHistory,
  
  });
