/**
 * NOT a default file
 * I am using this now, instead of VisitorRoutes HOC
 * Its very similar to Header, just removed everything 
 * that is not needed
 */

import { i18n } from 'src/i18n';
import blueHeaderLogo from 'src/static/images/blueHeaderLogo.jpeg';
import whiteIcon from 'src/static/images/whiteIcon.jpeg';
import {
  AppBar,
  Toolbar,
  // added below
  Box,
  List,
  ListItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles'; // mui v5x migration
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// import selectors from 'src/modules/auth/authSelectors'; this is used with the default logoUrl
// import { useSelector } from 'react-redux';

// I'm keeping this makeStyles until I know of a
// better way to customize with emotion
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    borderBottom: '0px solid #dee2e6',
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    marginTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    fontWeight: 500,
    fontSize: '1.5rem',
    color: theme.palette.getContrastText(
      theme.palette.primary.main,
    ),
    textDecoration: 'none',
    border: '0px solid red',
  },
  staticMenu: {
    display: 'flex', 
    flexDirection: 'row', 
    padding: '0px',
  },
  staticMenuLinks: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  }
}));

function StaticHeader() {
  const classes = useStyles();
  const location = useLocation(); // added

  return (
    <AppBar className={classes.appBar} position="static" elevation={0}>
      <Toolbar>
        {/* default location for IconButton/MenuIcon */}

        <Box mt={0} pl={0}>
          <Link className={classes.logo} to="/webhp">
            { 
              location.pathname === '/' || 
              location.pathname === '/webhp' || 
              location.pathname === '/vhclshp' || 
              location.pathname === '/prtshp' || 
              location.pathname === '/nwshp' ? 
            <img
              src={whiteIcon}
              width="50px" // 225px
              alt={i18n('app.title')}
              style={{ verticalAlign: 'middle', border: '0px solid black'}}
            />
            : 
            <img
              src={blueHeaderLogo}
              width="180px" // 225px
              alt={i18n('app.title')}
              style={{ verticalAlign: 'middle', border: '0px solid black' }}
            />
            }
            </Link>
          </Box>

        <Box display="flex" flexGrow={1} />

          {/* default location for internationalization */}
            
          <List className={classes.staticMenu}>
            <ListItem>
              <Link 
                to="/page/about" 
                // target="_blank" 
                // rel="noopener noreferrer" 
                className={classes.staticMenuLinks}
              >
                About
              </Link>
            </ListItem>
            <ListItem>
              <Link 
                to="/faq" 
                // target="_blank"
                // rel="noopener noreferrer" 
                className={classes.staticMenuLinks}
              >
                FAQ
              </Link>
            </ListItem>
          </List>

      </Toolbar>

      {/* future location for search toolbar */}
      
    </AppBar>
  );
}

export default StaticHeader;
