import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/userVehicle/importer/userVehicleImporterSelectors';
import UserVehicleService from 'src/modules/userVehicle/userVehicleService';
import fields from 'src/modules/userVehicle/importer/userVehicleImporterFields';
import { i18n } from 'src/i18n';

const userVehicleImporterActions = importerActions(
  'USERVEHICLE_IMPORTER',
  selectors,
  UserVehicleService.import,
  fields,
  i18n('entities.userVehicle.importer.fileName'),
);

export default userVehicleImporterActions;