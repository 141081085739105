/**
 * NOT a default file
 * modeled after Layout
 * 
 * created to add custom styling to all /profile pages
 * removed much of the comments from Layout
 * 
 *         ### ALWAYS ###
 * check Layout.tsx BEFORE making major changes
 * 
 */


import Header from 'src/view/layout/Header';
// import Menu from 'src/view/layout/Menu';
import RootWrapper from 'src/view/layout/styles/RootWrapper';
import CustomContentWrapper from 'src/view/layout/styles/CustomContentWrapper';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles'; // mui v5x migration
import Footer from 'src/view/layout/Footer';
import { ScrollToTop } from 'src/utils';


const useStyles = makeStyles((theme) => ({
  // I'm keeping this UNTIL I can completely remove makeStyles from codebase
  // ***KEEP***
  // this seems to be necessary when using Appbar with fixed placement
  // https://v4.mui.com/components/app-bar/#fixed-placement
  toolbar: theme.mixins.toolbar, // default
}));

function ProfileLayout(props) {
  const classes = useStyles();

  return (
    <RootWrapper>
      <Header />

      <CustomContentWrapper>
        <div className={classes.toolbar}></div>
        {props.children}
      </CustomContentWrapper>

      <Box borderTop={3} borderColor="#d5d5d5">
        <ScrollToTop />
          <Footer />
      </Box>
    </RootWrapper>
  );
}

export default ProfileLayout;