/**
 * NOT a default file
 * Modeled after PublicRoute ***(verbatim)***
 * Used for auth routes view (signin/signup etc)
 * Enables PublicRoutes to be repurposed
 */

import PermissionChecker from 'src/modules/auth/permissionChecker';
import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

function AuthRoute({
  component: Component,
  currentTenant,
  currentUser,
  ...rest
}) {
  
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(
          currentTenant,
          currentUser,
        );

        if (permissionChecker.isAuthenticated) {
          return <Redirect // to="/webhp" />;
            to={{
              pathname: '/webhp',
              state: { from: location },
            }}
          />
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default AuthRoute;
