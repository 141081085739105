import list from 'src/modules/engine/list/engineListReducers';
import form from 'src/modules/engine/form/engineFormReducers';
import view from 'src/modules/engine/view/engineViewReducers';
import destroy from 'src/modules/engine/destroy/engineDestroyReducers';
import importerReducer from 'src/modules/engine/importer/engineImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
