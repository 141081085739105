/**
 * NOT a default file
 * modeled after Layout
 * to be used for static content related pages
 * mostly from Page and Policy Ents
 * 
 */

import StaticHeader from 'src/view/layout/StaticHeader';
import { Box } from '@mui/material';

// added pkgs
import RootWrapper from 'src/view/layout/styles/RootWrapper';
import CustomContentWrapper from 'src/view/layout/styles/CustomContentWrapper';
import Footer from 'src/view/layout/Footer';
import { ScrollToTop } from 'src/utils';

function StaticLayout(props) {

  return (
    <RootWrapper>
      <StaticHeader />

      <CustomContentWrapper>
        {props.children}
      </CustomContentWrapper>

      <Box borderTop={3} borderColor="#d5d5d5">
        <ScrollToTop />
          <Footer />
      </Box>
    </RootWrapper>
  );
}

export default StaticLayout;