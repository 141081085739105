import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'year',
    label: i18n('entities.year.fields.year'),
    schema: schemas.string(
      i18n('entities.year.fields.year'),
      {
        "min": 4,
        "max": 4
      },
    ),
  },
];