import authAxios from 'src/modules/shared/axios/authAxios';
// import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'; // commented out for visitorRoutes

export default class PageService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    // const tenantId = AuthCurrentTenant.get(); // commented out for visitorRoutes

    const response = await authAxios.put(
      // `/tenant/${tenantId}/page/${id}`, // default
      `/tenant/default/page/${id}`, // visitorRoutes
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    // const tenantId = AuthCurrentTenant.get(); // commented out for visitorRoutes

    const response = await authAxios.delete(
      // `/tenant/${tenantId}/page`, // default
      `/tenant/default/page`, // visitorRoutes
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    // const tenantId = AuthCurrentTenant.get(); // commented out for visitorRoutes

    const response = await authAxios.post(
      // `/tenant/${tenantId}/page`, // default
      `/tenant/default/page`, // visitorRoutes
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    // const tenantId = AuthCurrentTenant.get(); // commented out for visitorRoutes

    const response = await authAxios.post(
      // `/tenant/${tenantId}/page/import`, // default
      `/tenant/default/page/import`, // visitorRoutes
      body,
    );

    return response.data;
  }

  static async find(id) {
    // const tenantId = AuthCurrentTenant.get(); // commented out for visitorRoutes

    const response = await authAxios.get(
      // `/tenant/${tenantId}/page/${id}`, // default
      `/tenant/default/page/${id}`, // visitorRoutes
    );

    return response.data;
  }

  // bof added slugs [Andre]
  static async findByIdentifier(identifier) {
    // const tenantId = AuthCurrentTenant.get(); // default code (added by Andre for slug work) needs to be commented out for VisitorRoutes to work

    const response = await authAxios.get(
      // `/tenant/${tenantId}/page/identifier/${identifier}`, // default code (added by Andre for slug work) needs to be commented out for VisitorRoutes to work
      `/tenant/default/page/identifier/${identifier}`, // visitorRoutes
    );

    return response.data;
  }
  // eof added slugs [Andre]

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    // const tenantId = AuthCurrentTenant.get(); // commented out for visitorRoutes

    const response = await authAxios.get(
      // `/tenant/${tenantId}/page`, // default
      `/tenant/default/page`, // visitorRoutes
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    // const tenantId = AuthCurrentTenant.get(); // commented out for visitorRoutes

    const response = await authAxios.get(
      // `/tenant/${tenantId}/page/autocomplete`, // default
      `/tenant/default/page/autocomplete`, // visitorRoutes
      {
        params,
      },
    );

    return response.data;
  }
}
