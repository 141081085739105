import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/engine/importer/engineImporterSelectors';
import EngineService from 'src/modules/engine/engineService';
import fields from 'src/modules/engine/importer/engineImporterFields';
import { i18n } from 'src/i18n';

const engineImporterActions = importerActions(
  'ENGINE_IMPORTER',
  selectors,
  EngineService.import,
  fields,
  i18n('entities.engine.importer.fileName'),
);

export default engineImporterActions;