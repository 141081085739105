const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'), // not authorized / forbidden
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),  // server error
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'), // 404 is not available / not found
  },
].filter(Boolean);

export default simpleRoutes;