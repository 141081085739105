/**
 * react-avatar replaces MU Avatar and AccountCircleIcon
 * added className to Menu to move it down
 * changed user routes, added /account
 * added but commented out /account/user-vehicle route and DriverEtaIcon until ready for use
 * changed the route for password change form to /account/profile
 *  moved password change form from its own page to profile page
 * used css to transform userEmail to lowercase and added fontsize to make a bit larger
 * 
 */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  // Avatar, replaced by react-avatar
} from '@mui/material';
import { makeStyles } from '@mui/styles'; // mui v5x migration
import authSelectors from 'src/modules/auth/authSelectors';
import { getHistory } from 'src/modules/store';
import authActions from 'src/modules/auth/authActions';
import { i18n } from 'src/i18n';
// import CodeIcon from '@mui/icons-material/Code';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockIcon from '@mui/icons-material/Lock';
import config from 'src/config';
import AppsIcon from '@mui/icons-material/Apps';

// added pkgs
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import DriveEtaIcon from '@mui/icons-material/DriveEta';
import HistoryIcon from '@mui/icons-material/History';
import Avatar from 'react-avatar';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';

// I'm keeping this makeStyles until I know of a
// better way to customize with emotion
const useStyles = makeStyles((theme) => ({
  button: {
    // @ts-ignore
    color: theme.palette.background.main,
  },
  buttonInner: {
    display: 'flex',
    alignItems: 'center',
    border: '0px solid green'
  },
  text: {
    margin: theme.spacing(0, 0.5, 0, 1),
    display: 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // @ts-ignore
    color: theme.palette.background.main,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    // added
    fontSize: '1rem',
    textTransform: 'lowercase'
  },
  optionText: {
    margin: theme.spacing(0, 0.5, 0, 1),
    // added below
    textDecoration: 'none',
    color: 'black'
  },
  // added classes below
  iconButton: {
    padding: '6px',
    // @ts-ignore
    color: theme.palette.primary.main,
  },
  expandMoreIcon: {
    // @ts-ignore
    color: theme.palette.primary.main,
  },
  // userMenu: { no longer necessary due to mui v5x migration, keeping for ref purposes
  //   border: '0px solid black',
  //   marginTop: theme.spacing(6), // moves menu down
  // }
}));

function UserMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const classes = useStyles();

  // userEmail and userFullName added below
  // const userEmail = useSelector(
  //   authSelectors.selectCurrentUserEmail,
  // );

  const userFullName = useSelector(
    authSelectors.selectCurrentUserFullName,
  );

  // default, this display either user's first name or email handle in Header
  const userText = useSelector(
    authSelectors.selectCurrentUserNameOrEmailPrefix,
  );
  const userAvatar = useSelector(
    authSelectors.selectCurrentUserAvatar,
  );
  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );
  // const currentUser = useSelector( // added for isAdmin check
  //   authSelectors.selectCurrentUser,
  // );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const doSignout = () => {
    dispatch(authActions.doSignout());
  };

  const doNavigateToProfile = () => {
    getHistory().push('/profile');
  };

  const doNavigateToPasswordChange = () => {
    getHistory().push('/profile');
    // getHistory().push('/password-change'); default
  };

  const doNavigateToTenants = () => {
    getHistory().push('/tenant');
  };

  // added routes
  const doNavigateToSearchHistory = () => {
    getHistory().push('/profile/search-history');
  };

  // const doNavigateToUserVehicle = () => {
  //   getHistory().push('/profile/user-vehicle');
  // };

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleClick}
      >
        <div className={classes.buttonInner}>
          {userAvatar && (
            <Avatar name={`${userFullName}`} color="#f0f2f5" fgColor="#1673e6" size={"30"} round={true} />
            // <Avatar src={userAvatar} alt="avatar" /> default
          )}
          {!userAvatar && 
          <Avatar name={`${userFullName}`} color="#f0f2f5" fgColor="#1673e6" size={"30"} round={true} /> }
          <div className={classes.text}>
            {/* <div>{userEmail}</div> */}
            <div>{userText}
              <IconButton className={classes.iconButton}>
                <ExpandMoreIcon className={classes.expandMoreIcon} />
              </IconButton>
            </div>
            {['multi', 'multi-with-subdomain'].includes(
              config.tenantMode,
            ) && <div>{currentTenant.name}</div>}
          </div>
        </div>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        // className={classes.userMenu} mui v5x migration, ref purps
        style={{border: '0px solid orange'}}
      >
        <MenuItem onClick={doNavigateToProfile}>
          <PersonOutlineIcon />
          <span className={classes.optionText}>
            {i18n('auth.profile.title')}
          </span>
        </MenuItem>
        <MenuItem onClick={doNavigateToPasswordChange}>
          <LockIcon />
          <span className={classes.optionText}>
            {i18n('auth.passwordChange.title')}
          </span>
        </MenuItem>
        {/* bof added routes */}
        <MenuItem onClick={doNavigateToSearchHistory}>
          <HistoryIcon />
          <span className={classes.optionText}>
            {i18n('entities.searchHistory.view.title')}
          </span>
        </MenuItem>
        <Divider variant="middle" />

        <Box sx={{my: 1, mx: 1}}>
        <Link to="/page/about" className={classes.optionText}>
          {i18n('static.about')}
        </Link>

        {/* <Link to="/faq" className={classes.optionText}>
          {i18n('entities.faq.list.title')}
        </Link> */}
        </Box>
        {/* <MenuItem onClick={doNavigateToUserVehicle}>
          <DriveEtaIcon />
          <span className={classes.optionText}>
            {i18n('entities.userVehicle.menu')}
          </span>
        </MenuItem> */}
        {/* eof added routes */}
        {['multi', 'multi-with-subdomain'].includes(
          config.tenantMode,
        ) && (
          <MenuItem onClick={doNavigateToTenants}>
            <AppsIcon />
            <span className={classes.optionText}>
              {i18n('auth.tenants')}
            </span>
          </MenuItem>
        )}
        {/* {config.apiDocumentationUrl && (
          <MenuItem
            component="a"
            href={config.apiDocumentationUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CodeIcon />
            <span className={classes.optionText}>
              {i18n('api.menu')}
            </span>
          </MenuItem>
        )} */}
        <Divider variant="middle" />
        <MenuItem onClick={doSignout}>
          <ExitToAppIcon />
          <span className={classes.optionText}>
            {i18n('auth.signout')}
          </span>
        </MenuItem>
        <Divider variant="middle" />
        <Box sx={{mx:1, my:1}}>
        <Link to="/policy/terms" className={classes.optionText}>
          {i18n('entities.policy.static.terms')}
        </Link>

        <Link to="/policy/privacy" className={classes.optionText}>
          {i18n('entities.policy.static.privacy')}
        </Link>
        </Box>
      </Menu>
    </>
  );
}

export default UserMenu;
