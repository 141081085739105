import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.page.fields.name'),
    schema: schemas.string(
      i18n('entities.page.fields.name'),
      {
        "required": true,
        "max": 35
      },
    ),
  },
  // bof added slug [Andre]
  { // added for huge refactor process
    name: 'slug',
    label: i18n('entities.page.fields.slug'),
    schema: schemas.string(
      i18n('entities.page.fields.slug'),
      {
        "required": true,
        "max": 100
      },
    ),
  },
  // eof added slug [Andre]
  {
    name: 'headingOne',
    label: i18n('entities.page.fields.headingOne'),
    schema: schemas.string(
      i18n('entities.page.fields.headingOne'),
      {
        "max": 35
      },
    ),
  },
  // bof added for huge refactor process
  {
    name: 'imageOne',
    label: i18n('entities.page.fields.imageOne'),
    schema: schemas.images(
      i18n('entities.page.fields.imageOne'),
      {},
    ),
  },
  {
    name: 'markeingTextOne',
    label: i18n('entities.page.fields.markeingTextOne'),
    schema: schemas.string(
      i18n('entities.page.fields.markeingTextOne'),
      {
        "max": 500
      },
    ),
  },
  {
    name: 'headingTwo',
    label: i18n('entities.page.fields.headingTwo'),
    schema: schemas.string(
      i18n('entities.page.fields.headingTwo'),
      {
        "max": 30
      },
    ),
  },
  {
    name: 'imageTwo',
    label: i18n('entities.page.fields.imageTwo'),
    schema: schemas.images(
      i18n('entities.page.fields.imageTwo'),
      {},
    ),
  },
  {
    name: 'marketingTextTwo',
    label: i18n('entities.page.fields.marketingTextTwo'),
    schema: schemas.string(
      i18n('entities.page.fields.marketingTextTwo'),
      {
        "max": 500
      },
    ),
  },
  {
    name: 'headingThree',
    label: i18n('entities.page.fields.headingThree'),
    schema: schemas.string(
      i18n('entities.page.fields.headingThree'),
      {
        "max": 30
      },
    ),
  },
  {
    name: 'imageThree',
    label: i18n('entities.page.fields.imageThree'),
    schema: schemas.images(
      i18n('entities.page.fields.imageThree'),
      {},
    ),
  },
  {
    name: 'marketingTextThree',
    label: i18n('entities.page.fields.marketingTextThree'),
    schema: schemas.string(
      i18n('entities.page.fields.marketingTextThree'),
      {
        "max": 500
      },
    ),
  },
  {
    name: 'headingFour',
    label: i18n('entities.page.fields.headingFour'),
    schema: schemas.string(
      i18n('entities.page.fields.headingFour'),
      {
        "max": 30
      },
    ),
  },
  {
    name: 'imageFour',
    label: i18n('entities.page.fields.imageFour'),
    schema: schemas.images(
      i18n('entities.page.fields.imageFour'),
      {},
    ),
  },
  {
    name: 'marketingTextFour',
    label: i18n('entities.page.fields.marketingTextFour'),
    schema: schemas.string(
      i18n('entities.page.fields.marketingTextFour'),
      {
        "max": 500
      },
    ),
  },
  {
    name: 'headingFive',
    label: i18n('entities.page.fields.headingFive'),
    schema: schemas.string(
      i18n('entities.page.fields.headingFive'),
      {
        "max": 30
      },
    ),
  },
  {
    name: 'imageFive',
    label: i18n('entities.page.fields.imageFive'),
    schema: schemas.images(
      i18n('entities.page.fields.imageFive'),
      {},
    ),
  },
  {
    name: 'marketingTextFive',
    label: i18n('entities.page.fields.marketingTextFive'),
    schema: schemas.string(
      i18n('entities.page.fields.marketingTextFive'),
      {
        "max": 500
      },
    ),
  },
  // eof added for huge refactor process
  {
    name: 'pageBody',
    label: i18n('entities.page.fields.pageBody'),
    schema: schemas.string(
      i18n('entities.page.fields.pageBody'),
      {
        "max": 500 // was 85000
      },
    ),
  },
];
