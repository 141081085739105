import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'state',
    label: i18n('entities.state.fields.state'),
    schema: schemas.string(
      i18n('entities.state.fields.state'),
      {}, // I removed the validations for huge refactor process
    ),
  },
];
