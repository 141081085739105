import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/searchHistory/importer/searchHistoryImporterSelectors';
import SearchHistoryService from 'src/modules/searchHistory/searchHistoryService';
import fields from 'src/modules/searchHistory/importer/searchHistoryImporterFields';
import { i18n } from 'src/i18n';

const searchHistoryImporterActions = importerActions(
  'SEARCHHISTORY_IMPORTER',
  selectors,
  SearchHistoryService.import,
  fields,
  i18n('entities.searchHistory.importer.fileName'),
);

export default searchHistoryImporterActions;