/**
 * ***IMPORTANT***
 * You must also change permission in backend/src/security/permissions
 * in order for any changes to WORK!!
 * 
 * Added user role to all userVehicle
 * 
 * user role was ONLY added to Read permission
 * 
 * ### Visitor Routes is NO LONGER IN ACTIVE USE ###
 * see README-FRONTEND.md for more info 
 * 
 * I've removed roles.user from many of the FUTURE features 
 * that are planned to hide their links in ProfileMenu
 * 
 * *** LEGACY ***  
 * Made changes to policy* for Visitor Routes
 *  Visitor Routes gives non-auth'd users access to routes
 *  Default behavior of SH is to lock everything down,
 *  Visitor Routes opens up any route(s) to non-auth'd users (visitors)
 *  add allowedPublic: true, comments out allowedRoles: ['roles.whatever']
 * 
 * user data access lock, searchHistory
 */

import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin, roles.user],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin, roles.user],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin, roles.user],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.admin, roles.custom, roles.user],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },

      

      stateImport: {
        id: 'stateImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      stateCreate: {
        id: 'stateCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      stateEdit: {
        id: 'stateEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      stateDestroy: {
        id: 'stateDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      stateRead: {
        id: 'stateRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      stateAutocomplete: {
        id: 'stateAutocomplete',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      // added user role to all userVehicle*
      userVehicleImport: {
        id: 'userVehicleImport',
        allowedRoles: [roles.admin, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userVehicleCreate: {
        id: 'userVehicleCreate',
        allowedRoles: [roles.admin, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.userVehiclePhotos,
        ],
      },
      userVehicleEdit: {
        id: 'userVehicleEdit',
        allowedRoles: [roles.admin, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.userVehiclePhotos,
        ],
      },
      userVehicleDestroy: {
        id: 'userVehicleDestroy',
        allowedRoles: [roles.admin, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.userVehiclePhotos,
        ],
      },
      userVehicleRead: {
        id: 'userVehicleRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userVehicleAutocomplete: {
        id: 'userVehicleAutocomplete',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      

      pageImport: {
        id: 'pageImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      pageCreate: {
        id: 'pageCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      pageEdit: {
        id: 'pageEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      pageDestroy: {
        id: 'pageDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      pageRead: { // ONLY this permission has the VisitorRoutes change added to it
        id: 'pageRead',
        allowedPublic: true, // added for VisitorRoutes, replaces allowedRoles when VisitorRoutes applied
        // allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      pageAutocomplete: {
        id: 'pageAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      policyImport: {
        id: 'policyImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      policyCreate: {
        id: 'policyCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      policyEdit: {
        id: 'policyEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      policyDestroy: {
        id: 'policyDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      policyRead: { // ONLY this permission has the VisitorRoutes change added to it
        id: 'policyRead',
        allowedPublic: true, // added for VisitorRoutes, replaces allowedRoles when VisitorRoutes applied
        // allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      policyAutocomplete: {
        id: 'policyAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      vehicleRecordImport: {
        id: 'vehicleRecordImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      vehicleRecordCreate: {
        id: 'vehicleRecordCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      vehicleRecordEdit: {
        id: 'vehicleRecordEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      vehicleRecordDestroy: {
        id: 'vehicleRecordDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      vehicleRecordRead: {
        id: 'vehicleRecordRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      vehicleRecordAutocomplete: {
        id: 'vehicleRecordAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      yearImport: {
        id: 'yearImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      yearCreate: {
        id: 'yearCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      yearEdit: {
        id: 'yearEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      yearDestroy: {
        id: 'yearDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      yearRead: {
        id: 'yearRead',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      yearAutocomplete: {
        id: 'yearAutocomplete',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      makeImport: {
        id: 'makeImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      makeCreate: {
        id: 'makeCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      makeEdit: {
        id: 'makeEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      makeDestroy: {
        id: 'makeDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      makeRead: {
        id: 'makeRead',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      makeAutocomplete: {
        id: 'makeAutocomplete',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      modelImport: {
        id: 'modelImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      modelCreate: {
        id: 'modelCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      modelEdit: {
        id: 'modelEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      modelDestroy: {
        id: 'modelDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      modelRead: {
        id: 'modelRead',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      modelAutocomplete: {
        id: 'modelAutocomplete',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      trimImport: {
        id: 'trimImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      trimCreate: {
        id: 'trimCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      trimEdit: {
        id: 'trimEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      trimDestroy: {
        id: 'trimDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      trimRead: {
        id: 'trimRead',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      trimAutocomplete: {
        id: 'trimAutocomplete',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      engineImport: {
        id: 'engineImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      engineCreate: {
        id: 'engineCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      engineEdit: {
        id: 'engineEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      engineDestroy: {
        id: 'engineDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      engineRead: {
        id: 'engineRead',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      engineAutocomplete: {
        id: 'engineAutocomplete',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      

      /**
       * added user and custom roles to Destory, Read and Autocomplete searchHistory permissions
       * this enables the user data access lock (prevents users from seeing others data)
       * only admin has access to ALL searchHistory permissions
       */
      searchHistoryImport: {
        id: 'searchHistoryImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      searchHistoryCreate: {
        id: 'searchHistoryCreate',
        allowedRoles: [roles.admin, roles.user], // used for testing user data access lock
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      searchHistoryEdit: {
        id: 'searchHistoryEdit',
        allowedRoles: [roles.admin, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      searchHistoryDestroy: {
        id: 'searchHistoryDestroy',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      searchHistoryRead: {
        id: 'searchHistoryRead',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      searchHistoryAutocomplete: {
        id: 'searchHistoryAutocomplete',
        allowedRoles: [roles.admin, roles.custom, roles.user],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      
      dashboardCreate: {
        id: 'dashboardCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      dashboardEdit: {
        id: 'dashboardEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      dashboardDestroy: {
        id: 'dashboardDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      dashboardRead: {
        id: 'dashboardRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      dashboardAutocomplete: {
        id: 'dashboardAutocomplete',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      // eof added for huge refactor process
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
