import list from 'src/modules/userVehicle/list/userVehicleListReducers';
import form from 'src/modules/userVehicle/form/userVehicleFormReducers';
import view from 'src/modules/userVehicle/view/userVehicleViewReducers';
import destroy from 'src/modules/userVehicle/destroy/userVehicleDestroyReducers';
import importerReducer from 'src/modules/userVehicle/importer/userVehicleImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
