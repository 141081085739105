import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.userVehicle.fields.id'),
  },
  {
    name: 'bio',
    label: i18n('entities.userVehicle.fields.bio'),
  },
  {
    name: 'year',
    label: i18n('entities.userVehicle.fields.year'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'make',
    label: i18n('entities.userVehicle.fields.make'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'model',
    label: i18n('entities.userVehicle.fields.model'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'trim',
    label: i18n('entities.userVehicle.fields.trim'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'engine',
    label: i18n('entities.userVehicle.fields.engine'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'description',
    label: i18n('entities.userVehicle.fields.description'),
  },
  {
    name: 'photos',
    label: i18n('entities.userVehicle.fields.photos'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.userVehicle.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.userVehicle.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];

/**
 * LEGACY FIELDS
 * REMOVED FOR HUGE REFACTOR PROCESS
 * 
 * {
    name: 'firstName',
    label: i18n('entities.userVehicle.fields.firstName'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'lastName',
    label: i18n('entities.userVehicle.fields.lastName'),
    render: exporterRenders.relationToOne(),
  },
 */
