import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'vehicleId',
    label: i18n('entities.vehicleRecord.fields.vehicleId'),
    schema: schemas.string(
      i18n('entities.vehicleRecord.fields.vehicleId'),
      {
        "max": 20 // "required": true
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.vehicleRecord.fields.year'),
    schema: schemas.relationToMany( // One
      i18n('entities.vehicleRecord.fields.year'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'make',
    label: i18n('entities.vehicleRecord.fields.make'),
    schema: schemas.relationToMany( // One
      i18n('entities.vehicleRecord.fields.make'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'model',
    label: i18n('entities.vehicleRecord.fields.model'),
    schema: schemas.relationToMany( // One
      i18n('entities.vehicleRecord.fields.model'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'trim',
    label: i18n('entities.vehicleRecord.fields.trim'),
    schema: schemas.relationToMany( // One
      i18n('entities.vehicleRecord.fields.trim'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'engine',
    label: i18n('entities.vehicleRecord.fields.engine'),
    schema: schemas.relationToMany( // One
      i18n('entities.vehicleRecord.fields.engine'),
      {
        "required": true
      },
    ),
  },
];