/**
 * NOT a default file
 * Does NOT use hooks, use classes
 * Follows the same css convention(HOC) that I was using with SHv5
 * useStyles is for the Hooks api, can not be used with React classes
 * 
 * Finally figured out to use emotion css to implement borderRadius on the search box
 *  implemented on both SmallSearchBox and AutohpViewPage
 * 
 */

import React from 'react';
// import  ResultNavigationLinks from 'src/view/shared/search/ResultNavigationLinks';
import { getParamsValue } from 'src/utils';
import { SearchBox } from '@appbaseio/reactivesearch';
import { css } from '@emotion/css';
import { withRouter } from 'react-router-dom';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
// import Button from '@mui/material/Button';
import { Box } from '@mui/material'
// import { withStyles } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
// import SearchIcon from '@mui/icons-material/Search';  // Web
// import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode'; //news
// import BuildIcon from '@mui/icons-material/Build'; // parts, tools
// import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'; // vehicles
// import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports'; // for racing results


class SmallSearchBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: getParamsValue(window.location.search)[props.id] || ""
    };
  }

  changeSelectedValue = value => {
    this.setState({
      selected: value
    });
  };

  render() {
    // @ts-ignore
    // const { selected } = this.state;
    // @ts-ignore
    const { id, history } = this.props; // classes,

    return (
      
      <Box color="#5a5a5a">
        <SearchBox
          placeholder="Small Search Box"
          style={{color: "#5a5a5a", textTransform: "lowercase", borderRadius: "6px"}}
          componentId={id}
          mode="select"
          dataField={[
            'article.headline', 'article.headline.autosuggest', 'article.headline.search', 'article.headline.keyword', 'author', 'article.author.autosuggest', 'article.author.search', 'article.author.keyword',
            'name', 'description', 'mainImage', 'url', 'sku', 'brand', 'mpn'
          ]}
          autosuggest={true}
          size={8}
          queryFormat="or"
          fuzziness={2}
          URLParams
          showIcon={true}
          icon={<Box pt={0.5} m="auto" color="primary.main" fontWeight={500} fontSize={30}><SearchSharpIcon /></Box>}
          iconPosition="right"
          showClear={true}
          clearIcon={<Box pt={0.5} mr={3} m="auto" color="#5a5a5a" fontWeight={500} fontSize={20} ><ClearIcon /></Box>}
          onValueChange={this.changeSelectedValue}
          onValueSelected={(value) => history.push(`/search?q="${value}"`)}
          // react={{ or: ['ResultsTabs'] }} // added
          innerClass={{
            input: css({
              borderRadius: '6px',
              textTransform: 'lowercase',
              fontWeight: 'normal',
              fontSize: '16px',
            }),
          }}
        />
        </Box>
        
    );
  }
}

//@ts-ignore
export default withRouter(SmallSearchBox);
// export default withRouter(withStyles(styles)(SmallSearchBox));