import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.policy.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.policy.fields.name'),
  },
  // bof added slug [Andre]
  {
    name: 'slug',
    label: i18n('entities.policy.fields.slug'),
  },
  // eof added slug [Andre]
  {
    name: 'heading',
    label: i18n('entities.policy.fields.heading'),
  },
  {
    name: 'policyBody',
    label: i18n('entities.policy.fields.policyBody'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.policy.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.policy.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
  { // added
    name: 'createdBy',
    label: i18n('entities.policy.fields.createdBy'),
  },
];
