import React from 'react';
import ReactDOM from 'react-dom';
import { i18n, init as i18nInit } from 'src/i18n';
import AuthService from 'src/modules/auth/authService';
import { AuthToken } from './modules/auth/authToken';
import TenantService from './modules/tenant/tenantService';

// added pkgs[mui v5x migration]
// @ts-ignore
// import * as ReactDOM from 'react-dom/client'; // for ReactDOM v18x
// @ts-ignore
// import { CssBaseline } from '@mui/material';
// import { ThemeProvider } from '@mui/material/styles';
// import siteTheme from 'src/theme/siteTheme';

// bof added for mui v5x migration for use with React/ReactDOM v18x
// const rootElement = document.getElementById('root');
// const root = ReactDOM.createRoot(rootElement);
// eof added for mui v5x migration

(async function () {
  const isSocialOnboardRequested = AuthService.isSocialOnboardRequested();
  AuthToken.applyFromLocationUrlIfExists();
  await TenantService.fetchAndApply();
  if (isSocialOnboardRequested) {
    await AuthService.socialOnboard();
  }
  await i18nInit();

  const App = require('./App').default;
  document.title = i18n('app.title');
  ReactDOM.render(<App />, document.getElementById('root')); // default

  // this is a hack of React / ReactDOM v17x with mui v5x migration for css styling
  // ReactDOM.render(
  //   <React.StrictMode>
  //     <ThemeProvider theme={siteTheme}>
  //       <CssBaseline />
  //         <App />
  //     </ThemeProvider>
  //   </React.StrictMode>, 
  //   document.getElementById('root')
  // );

  // for React / ReactDOM v18x
  // root.render( 
  //   <ThemeProvider theme={siteTheme}>
  //     <CssBaseline />
  //     <App />
  //   </ThemeProvider>
  // )
})();
