/**
 * 
 * ### publicRoutes has been renamed searchRoutes ###
 * 
 * publicRoutes has now been repurposed to serve as the view for search routes
 * renders regardless of auth status
 * search routes are non default and mainly static html
 * I may rename them it to searchRoutes
 * 
 */

const searchRoutes = [
  { 
    path: '/',
    loader: () => import('src/view/search/view/AutohpViewPage'),
    permissionRequired: null,
    exact: true, // this is req'd for RR to properly render the correct search routes
  },
  { // ORIGINAL default route
    // Default rendering of this route was the charts for dashboard
    // I've changed the default home route to render the Search component (autocomplete jumbotron)
    // default iconn was Dashboard, switched to Home
    path: '/webhp', // webhp
    loader: () => import('src/view/search/view/AutohpViewPage'),
    permissionRequired: null,
    exact: true, // this is req'd for RR to properly render the correct search routes
  },
  {
    path: '/vehicleshp', // vhclshp
    loader: () => import('src/view/search/view/VehicleshpViewPage'),
    permissionRequired: null,
    exact: true, // this is req'd for RR to properly render the correct search routes
  },
  {
    path: '/partshp', // prtshp
    loader: () => import('src/view/search/view/PartshpViewPage'),
    permissionRequired: null,
    exact: true, // this is req'd for RR to properly render the correct search routes
  },
  {
    path: '/newshp', // nwshp
    loader: () => import('src/view/search/view/NewshpViewPage'),
    permissionRequired: null,
    exact: true, // this is req'd for RR to properly render the correct search routes
  },
  {
    path: '/racinghp',
    loader: () => import('src/view/search/view/RacinghpViewPage'),
    permissionRequired: null,
    exact: true, // this is req'd for RR to properly render the correct search routes
  },

  // THESE 5 search results routes MUST be here for the Redirect code to work
  { 
    // CAN NOT BE '/', MUST BE '/search', otherwise, /auth/sigin does NOT work correctly
    // MUST stay toward the bottom of the array (near this catch all) for RR rendering of results query view functionality
    // is the template page for ALL results
    // the last 2 props ARE REQ'D on all search results routes
    path: '/search', // `/search?q="${value}"`,
    loader: () => import('src/view/results/web/view/WebResultsViewPage'),
    permissionRequired: null, // this is req'd so that the results query view functionality works properly
    exact: false, // this is req'd so that the results query view functionality works properly
  },
  { 
    path: '/vhclsrp',
    loader: () => import('src/view/results/web/view/WebResultsViewPage'),
    permissionRequired: null,
    exact: false,
  },
  { 
    path: '/prtsrp',
    loader: () => import('src/view/results/web/view/WebResultsViewPage'),
    permissionRequired: null,
    exact: false,
  },
  { 
    path: '/nwsrp',
    loader: () => import('src/view/results/web/view/WebResultsViewPage'),
    permissionRequired: null,
    exact: false,
  },
  { 
    path: '/rcngrp',
    loader: () => import('src/view/results/web/view/WebResultsViewPage'),
    permissionRequired: null,
    exact: false,
  },
].filter(Boolean);

export default searchRoutes;