import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.vehicleRecord.fields.id'),
  },
  {
    name: 'vehicleId',
    label: i18n('entities.vehicleRecord.fields.vehicleId'),
  },
  {
    name: 'year',
    label: i18n('entities.vehicleRecord.fields.year'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'make',
    label: i18n('entities.vehicleRecord.fields.make'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'model',
    label: i18n('entities.vehicleRecord.fields.model'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'trim',
    label: i18n('entities.vehicleRecord.fields.trim'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'engine',
    label: i18n('entities.vehicleRecord.fields.engine'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.vehicleRecord.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.vehicleRecord.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
