import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'engine',
    label: i18n('entities.engine.fields.engine'),
    schema: schemas.string(
      i18n('entities.engine.fields.engine'),
      {
        "min": 1,
        "max": 35
      },
    ),
  },
];