/**
 * 
 * NOT a default file
 * modeled after Layout
 * 
 * KEEPING comments from Layout until 
 * ALL routing is more formally finalized
 * 
 * * Until* Felipe releases his big update with Nextjs:
 * RootWrapper replaces root css class
 * CustomContentWrapper replaces content css class
 * 
 */


import Header from 'src/view/layout/Header';
// import Menu from 'src/view/layout/Menu';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles'; // mui v5x migration
// import { useRouteMatch, useLocation } from 'react-router-dom';

// added pkgs
import RootSearchWrapper from 'src/view/layout/styles/RootSearchWrapper';
import CustomContentWrapper from 'src/view/layout/styles/CustomContentWrapper';
import Footer from 'src/view/layout/Footer';
import { ScrollToTop } from 'src/utils';
// import AccountMenu from 'src/view/layout/AccountMenu';

const useStyles = makeStyles((theme) => ({
  // I'm keeping makeStyles/useStyles UNTIL I can completely remove makeStyles from codebase
  // ***KEEP***
  // this seems to be necessary when using Appbar with fixed placement
  // https://v4.mui.com/components/app-bar/#fixed-placement
  toolbar: theme.mixins.toolbar, // default
}));

function SearchLayout(props) {
  const classes = useStyles();
  
  // not removing these hooks yet
  // want to see if I can use them here 
  // instead of per Ent List/View
  // const match = useRouteMatch();
  // const location = useLocation();

  return (
    <RootSearchWrapper>
      <Header />

      {/* 
        VisitorHeader does not seem to be needed anyomore
        <AccountMenu now being rendered in *ListPage or *ViewPage - 
        AccountMenu will eventually be removed fromt these pages and 
        below will become the preferred way of doing it.

        I'm going to eventually Grid this and reinstate AccountMenu here
        I also want use useRouteMatch to render AccountMenu based on route
        I'm going to split Account Menu into 2, AccountUserMenu, AccountAdminMenu
        and use isAdmin check
      */}

      <CustomContentWrapper>
        <div className={classes.toolbar}></div>
        {props.children}
      </CustomContentWrapper>

      <Box borderTop={3} borderColor="#d5d5d5">
        <ScrollToTop />
          <Footer />
      </Box>
    </RootSearchWrapper>
  );
}

export default SearchLayout;


