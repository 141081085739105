import { useDispatch, useSelector } from 'react-redux';
// import I18nSelect from 'src/view/layout/I18nSelect';
import {
  AppBar,
  Toolbar,
  IconButton,
  // added below
  Box,
  Button,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles'; // mui v5x
import MenuIcon from '@mui/icons-material/Menu';
import UserMenu from 'src/view/layout/UserMenu';
import { Link } from 'react-router-dom';
import { i18n } from 'src/i18n';
// import selectors from 'src/modules/auth/authSelectors'; this is used with the default logoUrl

// added pkgs
import AppMenu from 'src/view/layout/AppMenu';
import layoutActions from 'src/modules/layout/layoutActions';
import authSelectors from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import SmallSearchBox from 'src/view/shared/search/SmallSearchBox';
import { useLocation } from 'react-router-dom';
import blueFontLogo from 'src/static/images/blueFontLogo.jpeg';
import largeBlueIcon from 'src/static/images/largeBlueIcon.jpg';

// I'm keeping this makeStyles until I know of a
// better way to customize with emotion
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.secondary.main,
    borderBottom: '3px solid #1673e6', // dee2e6
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    // I'm waiting to delete these 2 rules until after prod deployment to make sure that logo image renders
    // fontWeight: 500,
    // fontSize: '1.5rem',
    color: theme.palette.getContrastText(
      theme.palette.primary.main,
    ),
    textDecoration: 'none',
  },
}));

function Header(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  
  const location = useLocation(); // added

  // 3 consts below added
  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const permissionChecker = new PermissionChecker( // PermissionChecker now expects 2 args, 
    currentTenant, // new, needed
    currentUser,
  );

  // const logoUrl = useSelector(selectors.selectLogoUrl); this will be used when I get the default logo code working

  const doToggleMenu = () => {
    dispatch(layoutActions.doToggleMenu());
  };

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar>
        {/* default location for IconButton/MenuIcon */}

        <Box mt={0} pl={0} fontWeight={500} fontSize="1.5rem" bgcolor="secondary.main">
          <Link to="/webhp">
            { 
              location.pathname === '/' || 
              location.pathname === '/webhp' || 
              location.pathname === '/vehicleshp' || 
              location.pathname === '/partshp' || 
              location.pathname === '/newshp' 
              ? 
            <img
              src={largeBlueIcon} // whiteIcon largeBlueIcon
              width="50px" // 225px
              alt={i18n('app.title')}
              style={{ verticalAlign: 'middle', border: '0px solid black'}}
            />
            : 
            <img
              src={blueFontLogo} // blueHeaderLogo
              width="200px" // 225px
              alt={i18n('app.title')}
              style={{ verticalAlign: 'middle', border: '0px solid red' }}
            />
            }
            </Link>
          </Box>

          {/* added search box and ternary, I've changed behavior so it ONLY displays results pages */}
          <Box ml={6} display="flex" justifyContent="flex-start" flexGrow={0.75} border={0} borderColor="black">
          {
            location.pathname === "/search" || 
            location.pathname === "/vhclsrp" || 
            location.pathname === "/prtsrp" || 
            location.pathname === "/nwsrp" 
            ?
            <Box mt={1} flexGrow={1}>
              <SmallSearchBox id="q" />
            </Box>
            : 
            null 
          }
          </Box>

          <Box display="flex" flexGrow={1} border={0} borderColor="red" />

          <Box>
            <Tooltip title="App Menu" placement="right-end">
              <IconButton
                edge="start"
                onClick={doToggleMenu}
                >
                  <AppMenu />
                </IconButton>
            </Tooltip>
          </Box>

          { // new location, added Tooltip and auth check
            //@ts-ignore
            !permissionChecker.isAdmin ?
              null
              :
              <Tooltip title="User Menu">
                <IconButton
                  edge="start"
                  onClick={doToggleMenu}
                  style={{boxShadow: 'none'}}
                >
                <MenuIcon />
              </IconButton>
              </Tooltip>
          }

          {/* default location for internationalization */}

          {/* added ternary check to toggle between button or usermenu */}
          {!permissionChecker.isAuthenticated ? 
            <Box m={0}>
              <Button
                component={Link}
                color="primary"
                variant="contained"
                size="large"
                to="/auth/signin"
                disableElevation
                disableRipple
                style={{ color: 'orange', textTransform: 'capitalize', textDecoration: 'none', fontSize: '14px'}}
              >
                {i18n('auth.signin')}
              </Button>
            </Box>  
            : 
            <UserMenu /> // default
          }

      </Toolbar>

      {/* future location for search toolbar */}
      
    </AppBar>
  );
}

export default Header;
