import VehicleRecordService from 'src/modules/vehicleRecord/vehicleRecordService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'VEHICLERECORD_VIEW';

const vehicleRecordViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: vehicleRecordViewActions.FIND_STARTED,
      });

      const record = await VehicleRecordService.find(id);

      dispatch({
        type: vehicleRecordViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: vehicleRecordViewActions.FIND_ERROR,
      });

      getHistory().push('/admin/vehicle-record');
    }
  },
};

export default vehicleRecordViewActions;
