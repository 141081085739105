/**
 * largely based on UserMenu
 * removed unecessary stuff[mostly]
 * 
 */
import { useState } from 'react';
// import { useDispatch } from 'react-redux';
import {
  Button,
  Menu,
  MenuItem,
  // Avatar, replaced by react-avatar
} from '@mui/material';
import { makeStyles } from '@mui/styles'; // mui v5x migration
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import AppsIcon from '@mui/icons-material/Apps';

// added pkgs
import { Grid } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import BuildIcon from '@mui/icons-material/Build'; // parts, tools
import DriveEtaIcon from '@mui/icons-material/DriveEta'; // vehicles
import SubjectIcon from '@mui/icons-material/Subject';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';

// I'm keeping this makeStyles for now until I know of a 
// better way to customize vs littering the code with Box sx={{...}}
const useStyles = makeStyles((theme) => ({
  button: {
    // @ts-ignore
    color: theme.palette.background.main,
  },
  buttonInner: {
    display: 'flex',
    alignItems: 'center',
    border: '0px solid green'
  },
  // added classes below
  optionText: {
    margin: theme.spacing(0, 0.5, 0, 1),
  },
  // appMenu: { no longer necessary due to mui v5x migration, keeping for ref purposes
  //   border: '0px solid black',
  //   marginTop: theme.spacing(6), // moves menu down
  // }
}));

function AppMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  // const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const doNavigateToWeb = () => {
    getHistory().push('/webhp');
  };

  const doNavigateToVehicles = () => {
    getHistory().push('/vehicleshp');
  };

  const doNavigateToParts = () => {
    getHistory().push('/partshp');
  };

  const doNavigateToNews = () => {
    getHistory().push('/newshp');
  };

  const doNavigateToRacing = () => {
    getHistory().push('/racinghp');
  };

  return (
    <>
    <div>
      <Button
        className={classes.button}
        onClick={handleClick}
      >
        <div className={classes.buttonInner}>
          <AppsIcon style={{color:'grey'}} />
        </div>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        // className={classes.appMenu} mui v5x migration, ref purps
        style={{border: '0px solid orange'}}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
        <MenuItem onClick={doNavigateToWeb}>
          <PublicIcon />
          <span className={classes.optionText}>
            {i18n('search.web')}
          </span>
        </MenuItem>
        <MenuItem onClick={doNavigateToVehicles}>
          <DriveEtaIcon />
          <span className={classes.optionText}>
            {i18n('search.vehicles')}
          </span>
        </MenuItem>
        <MenuItem onClick={doNavigateToParts}>
          <BuildIcon />
          <span className={classes.optionText}>
            {i18n('search.parts')}
          </span>
        </MenuItem>
        <MenuItem onClick={doNavigateToNews}>
          <SubjectIcon />
          <span className={classes.optionText}>
            {i18n('search.news')}
          </span>
        </MenuItem>
        <MenuItem onClick={doNavigateToRacing}>
          <SportsMotorsportsIcon />
          <span className={classes.optionText}>
            {i18n('search.racing')}
          </span>
        </MenuItem>
        </Grid>
      </Menu>
      </div>
    </>
  );
}

export default AppMenu;


// const AppIcons = [
//   {
//     icon: <PublicIcon />,
//     url: ['/webhp'],
//     text: 'Web',
//   },
//   {
//     icon: <DirectionsCarIcon />,
//     url: ['/vehicleshp'],
//     text: 'Vehicles',
//   },
//   {
//     icon: <BuildIcon />,
//     url: ['/partshp'],
//     text: 'Parts',
//   },
//   {
//     icon: <SubjectIcon />,
//     url: ['/newshp'],
//     text: 'News',
//   },
//   {
//     icon: <SportsMotorsportsIcon />,
//     url: ['/racinghp'],
//     text: 'Racing',
//   },
// ];

// {AppIcons.map((AppIcon) => (
//   <Card
//     key={AppIcon.text}
//     elevation={0}
//   >
//   <Link
//     to={`${AppIcon.url}`}
//   >
//   {
//     <>
//       <MenuItem onClick={doNavigateToProfile}>
//         {AppIcon.icon}
//         {AppIcon.text}
//       </MenuItem>
//   </>
// }
// </Link>
// </Card>
// ))}
