import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'searchQuery',
    label: i18n('entities.searchHistory.fields.searchQuery'),
    schema: schemas.string(
      i18n('entities.searchHistory.fields.searchQuery'),
      {},
    ),
  },
];