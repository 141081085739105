/**
 * Analytics changed with RSv3
 * Added a prop to ReactiveBase for Analytics
 * 
 * Used this demo/example project to get the styling related
 * imports and components setup
 * https://github.com/dunky11/react-saas-template/blob/master/src/App.js
 */

import { ConnectedRouter } from 'connected-react-router';
import {
  configureStore,
  getHistory,
} from 'src/modules/store';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import RoutesComponent from 'src/view/shared/routes/RoutesComponent';
import 'typeface-roboto';
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material'; // mui v5x migration
import { SnackbarProvider, useSnackbar } from 'notistack';
import Message from 'src/view/shared/message';

// added pkgs
import { ReactiveBase } from '@appbaseio/reactivesearch';
import { APP, URL, REACTIVESEARCHAPICONFIG } from 'src/config/constants'; // CRED, APPBASECONFIG
import siteTheme from 'src/theme/siteTheme';

const store = configureStore();

export default function App(props) {
  // bof not default
  // https://stackoverflow.com/questions/70368760/react-uncaught-referenceerror-process-is-not-defined
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  // eof not default
  return (
    <Provider store={store}>
      <AppWithRedux {...props} />
    </Provider>
  );
}

function AppWithRedux(props) {
  

  // for custom Snackbar styling
  // const useStyles = makeStyles((theme) => ({
  //   snacks: {
  //     success: theme.palette.getContrastText(
  //       theme.palette.success.main,
  //     ),
  //     error: theme.palette.getContrastText(
  //       theme.palette.error.main,
  //     ),
  //     warning: theme.palette.getContrastText(
  //       theme.palette.warning.main,
  //     ),
  //     info: theme.palette.getContrastText(
  //       theme.palette.info.main,
  //     ),
  //   },
  // }));

  // const snacks = useStyles(); // for custom Snackbar styling

  return ( // credentials={CRED} 
    <ReactiveBase app={APP} url={URL} 
      //@ts-ignore 
      reactivesearchAPIConfig={REACTIVESEARCHAPICONFIG}
      >
      <StyledEngineProvider injectFirst> 
        <ThemeProvider theme={siteTheme}>
          <SnackbarProvider 
            maxSnack={3} 
            anchorOrigin={{vertical:'bottom',horizontal:'right'}} 
            iconVariant={{success:'✅',error:'✖️',warning:'⚠️',info:'ℹ️'}}
            // @ts-ignore
            // snacks={{success:snacks.success,error:snacks.error,warning:snacks.warning,info:snacks.info}}
          >
            <>
              <CssBaseline />
              <AppWithSnackbar {...props} />
            </>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ReactiveBase>
  );
}

function AppWithSnackbar(props) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // this is a little hack to not have to import notistack
    // on all the components that emit messages
    Message.registerNotistakEnqueueSnackbar(
      enqueueSnackbar,
    );
  }, [enqueueSnackbar]);

  return (
    <>
      <ConnectedRouter history={getHistory()}>
        
        <RoutesComponent />
        
      </ConnectedRouter>
    </>
  );
}
