import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/trim/importer/trimImporterSelectors';
import TrimService from 'src/modules/trim/trimService';
import fields from 'src/modules/trim/importer/trimImporterFields';
import { i18n } from 'src/i18n';

const trimImporterActions = importerActions(
  'TRIM_IMPORTER',
  selectors,
  TrimService.import,
  fields,
  i18n('entities.trim.importer.fileName'),
);

export default trimImporterActions;
