import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'year',
    label: i18n('entities.userVehicle.fields.year'),
    schema: schemas.relationToMany(
      i18n('entities.userVehicle.fields.year'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'make',
    label: i18n('entities.userVehicle.fields.make'),
    schema: schemas.relationToMany(
      i18n('entities.userVehicle.fields.make'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'model',
    label: i18n('entities.userVehicle.fields.model'),
    schema: schemas.relationToMany(
      i18n('entities.userVehicle.fields.model'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'trim',
    label: i18n('entities.userVehicle.fields.trim'),
    schema: schemas.relationToMany(
      i18n('entities.userVehicle.fields.trim'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'engine',
    label: i18n('entities.userVehicle.fields.engine'),
    schema: schemas.relationToMany(
      i18n('entities.userVehicle.fields.engine'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.userVehicle.fields.description'),
    schema: schemas.string(
      i18n('entities.userVehicle.fields.description'),
      {
        "min": 10,
        "max": 255
      },
    ),
  },
  {
    name: 'photos',
    label: i18n('entities.userVehicle.fields.photos'),
    schema: schemas.images(
      i18n('entities.userVehicle.fields.photos'),
      {
        "max": 4
      },
    ),
  },
];

/**
 * LEGACY FIELDS
 * REMOVED FOR HUGE REFACTOR PROCESS
 * 
 * {
    name: 'firstName',
    label: i18n('entities.userVehicle.fields.firstName'),
    schema: schemas.relationToOne(
      i18n('entities.userVehicle.fields.firstName'),
      {},
    ),
  },
  {
    name: 'lastName',
    label: i18n('entities.userVehicle.fields.lastName'),
    schema: schemas.relationToOne(
      i18n('entities.userVehicle.fields.lastName'),
      {},
    ),
  },
 */