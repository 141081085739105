/**
 * 
 * ### publicRoutes has been renamed searchRoutes ###
 * 
 * routes have been refactored here
 * they are now in their own individual files
 * and then imported and exported here
 * i simply took each individual route array
 * and placed it into its own file, then, 
 * export default typeRoutes.tsx
 * 
 * hopefully this will make all the routes a bit 
 * more manageable
 */

import privateRoutes from 'src/view/routes/privateRoutes';
import searchRoutes from 'src/view/routes/searchRoutes';
import authRoutes from 'src/view/routes/authRoutes';
import staticRoutes from 'src/view/routes/staticRoutes';
import emptyTenantRoutes from 'src/view/routes/emptyTenantRoutes';
import emptyPermissionsRoutes from 'src/view/routes/emptyPermissionsRoutes';
import emailUnverifiedRoutes from 'src/view/routes/emailUnverifiedRoutes';
import simpleRoutes from 'src/view/routes/simpleRoutes';

export default {
  privateRoutes,
  searchRoutes,
  authRoutes,
  staticRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};