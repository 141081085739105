/**
 * NOT a default file
 * Largely carryover from SHv5
 * Some things did change (mainly naming)
 * settings and colors stay in App because he is using Hooks
 *  AppWithRedux is a function component, he uses that to setup the default theme setup
 * Good grey for light font: 5a5a5a
 * If I can think of a way to add a function component here and then,
 * import it to AppWithRedux in App,
 * I can then use settings and colors here
 * https://material-ui.com/customization/theming/#createmuitheme-options-args-theme
 * https://v4.mui.com/customization/default-theme/?expand-path=$.palette
 * https://v4.mui.com/system/palette/    colors via markup, via wrapping the element in Box
 * Good light grey: #d5d5d5, #fafafa'
 *
 */

// bof Felipe's config and setup for theme
// I don't really use Felipe's config and setup for theme
// I dont think I need all this code but keeping until know for sure
// these were the default pkgs used to build theme[moved to siteTheme]
// import { useSelector } from 'react-redux';
// import authSelectors from 'src/modules/auth/authSelectors';
// import settingsThemeConverter from 'src/modules/settings/settingsThemeConverter';

// this was under: function AppWithRedux(props) {
// const settings = useSelector(
  //   authSelectors.selectCurrentSettings,
  // );

  // const colors = settingsThemeConverter.fromString(
  //   settings && settings.theme,
  // );
// eof Felipe's config and setup for theme

import { createTheme } from '@mui/material/styles'; // mui v5x migration

// mui v5x migration
// https://mui.com/material-ui/migration/v5-style-changes/#%E2%9C%85-add-module-augmentation-for-defaulttheme-typescript
// https://mui.com/material-ui/customization/breakpoints/
import { Theme } from '@mui/material/styles';
declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

const siteTheme = createTheme({
  palette: {
    primary: {
      main: '#1673e6', // 1673e6(seatgeek blue) / 007bff / 174ff4 / 556cd6
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff', // 2fa4e7(legacy blue) / 19857b
      contrastText: '#1673e6',
    },
    error: {
      main: '#f44336',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff9800',
      contrastText: '#fff',
    },
    info: {
      main: '#2196f3',
      contrastText: '#fff',
    },
    success: {
      main: '#4caf50',
      contrastText: '#fff',
    },
    background: {
      // @ts-ignore
      main: '#1673e6',
      contrastText: '#fff'
    },
    // white: {
    //   100: '#fff',
    // },
    grey: {
      // @ts-ignore
      main: '#757575', 
      // 600: '#757575',
      700: '#313131',
    }
  },
  // https://v4.mui.com/customization/typography/#responsive-font-sizes
  // https://v4.mui.com/customization/typography/#default-values
  typography: {
    fontSize: 14, // 12.5,
    // @ts-ignore
    h1: '8rem',
    // @ts-ignore
    h2: '6rem',
    // @ts-ignore
    h3: '4rem',
    // @ts-ignore
    h4: '1rem',
    // @ts-ignore
    h5: '0.5rem',
    // @ts-ignore
    h6: '0.2rem',
    // @ts-ignore
    body1: '1rem',
    // @ts-ignore
    body2: '2rem',
  },
  // added below
  // @ts-ignore
  contrastThreshold: 3,
  tonalOffset: 0.2
});

export default siteTheme;

// https://stackoverflow.com/questions/52043271/how-to-over-ride-an-media-css-for-a-material-ui-react-component
//@ts-ignore
// components: {
//   MuiToolbar: {
//     regular: {
//       height: "0px",
//       minHeight: "0px",
//       //@ts-ignore
//       // [defaultTheme.breakpoints.up('md')]: {
//       '@media(min-width: 600px)' : {
//         minHeight: "0px"
//       }
//     },
//   }
// },
// h3: {
//   fontSize: '2.4rem',
// },
// @ts-ignore
// theme.typography.h3 = {
//   fontSize: '1.2rem',
//   '@media (min-width: 600px'): {
//     fontSize: '1.5rem',
//   },
//   [theme.breakpoints.up('md')]: {
//     fontSize: '2.4rem',
//   },
// },