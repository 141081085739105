import list from 'src/modules/trim/list/trimListReducers';
import form from 'src/modules/trim/form/trimFormReducers';
import view from 'src/modules/trim/view/trimViewReducers';
import destroy from 'src/modules/trim/destroy/trimDestroyReducers';
import importerReducer from 'src/modules/trim/importer/trimImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
