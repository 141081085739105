/**
 * NOT a default file
 * modeled after the default but customized PublicRoute
 * used for static content pages
 * mostly for Policy and Page, where auth is NOT req'd
 * 
 */

import React from 'react';
import { Route, useLocation } from 'react-router-dom'; // Redirect was included by default

// added pkgs
import StaticLayout from 'src/view/layout/StaticLayout';

function StaticRoute({
  component: Component,
  ...rest
}) {
  const location = useLocation();

  console.log('using StaticRoute: route before return is: ', location.pathname);
  

  return (
    <Route
      {...rest}
      render={() => { // props

        return ( // Layout {...props} is NOT default  |  <Component {...props}
          <StaticLayout>
            <Component />
          </StaticLayout>
        )
      }}
    />
  );
}

export default StaticRoute;