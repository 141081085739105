import list from 'src/modules/year/list/yearListReducers';
import form from 'src/modules/year/form/yearFormReducers';
import view from 'src/modules/year/view/yearViewReducers';
import destroy from 'src/modules/year/destroy/yearDestroyReducers';
import importerReducer from 'src/modules/year/importer/yearImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
