import Permissions from 'src/security/permissions';
import config from 'src/config';
const permissions = Permissions.values;

const privateRoutes = [
  {// original default home route
    path: '/admin/dashboard', // '/'
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile', // '/profile'auth
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  // { ### I'm commenting this route out becuase ONLY want password changed accessible on profile page ###
  //   path: '/password-change',
  //   loader: () =>
  //     import('src/view/auth/PasswordChangeFormPage'),
  //   permissionRequired: null,
  //   exact: true,
  // },

  {
    path: '/admin/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/admin/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/admin/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/admin/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  { // this is where users are given roles *ADMIN ONLY*
    // these were the original default /user routes
    path: '/admin/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/admin/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/admin/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/admin/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/admin/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/admin/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/admin/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  

  {
    path: '/admin/state',
    loader: () =>
      import('src/view/state/list/StateListPage'),
    permissionRequired: permissions.stateRead,
    exact: true,
  },
  {
    path: '/admin/state/new',
    loader: () =>
      import('src/view/state/form/StateFormPage'),
    permissionRequired: permissions.stateCreate,
    exact: true,
  },
  {
    path: '/admin/state/importer',
    loader: () =>
      import(
        'src/view/state/importer/StateImporterPage'
      ),
    permissionRequired: permissions.stateImport,
    exact: true,
  },
  {
    path: '/admin/state/:id/edit',
    loader: () =>
      import('src/view/state/form/StateFormPage'),
    permissionRequired: permissions.stateEdit,
    exact: true,
  },
  {
    path: '/admin/state/:id',
    loader: () =>
      import('src/view/state/view/StateViewPage'),
    permissionRequired: permissions.stateRead,
    exact: true,
  },

  {
    path: '/profile/user-vehicle',
    loader: () =>
      import('src/view/userVehicle/list/UserVehicleListPage'),
    permissionRequired: permissions.userVehicleRead,
    exact: true,
  },
  {
    path: '/profile/user-vehicle/new',
    loader: () =>
      import('src/view/userVehicle/form/UserVehicleFormPage'),
    permissionRequired: permissions.userVehicleCreate,
    exact: true,
  },
  {
    path: '/profile/user-vehicle/importer',
    loader: () =>
      import(
        'src/view/userVehicle/importer/UserVehicleImporterPage'
      ),
    permissionRequired: permissions.userVehicleImport,
    exact: true,
  },
  {
    path: '/profile/user-vehicle/:id/edit',
    loader: () =>
      import('src/view/userVehicle/form/UserVehicleFormPage'),
    permissionRequired: permissions.userVehicleEdit,
    exact: true,
  },
  {
    path: '/profile/user-vehicle/:id',
    loader: () =>
      import('src/view/userVehicle/view/UserVehicleViewPage'),
    permissionRequired: permissions.userVehicleRead,
    exact: true,
  },

  

  { // THIS route lists ALL the pages
    path: '/admin/page',
    loader: () =>
      import('src/view/page/list/PageListPage'),
    permissionRequired: permissions.pageRead,
    exact: true,
  },
  {
    path: '/admin/page/new',
    loader: () =>
      import('src/view/page/form/PageFormPage'),
    permissionRequired: permissions.pageCreate,
    exact: true,
  },
  {
    path: '/admin/page/importer',
    loader: () =>
      import(
        'src/view/page/importer/PageImporterPage'
      ),
    permissionRequired: permissions.pageImport,
    exact: true,
  },
  {
    path: '/admin/page/:id/edit',
    loader: () =>
      import('src/view/page/form/PageFormPage'),
    permissionRequired: permissions.pageEdit,
    exact: true,
  },
  // THIS route does NOT need auth and is in 
  // staticRoutes
  // { THIS route does NOT need to be private
  //   path: '/admin/page/:id',
  //   loader: () =>
  //     import('src/view/page/view/PageViewPage'),
  //   permissionRequired: permissions.pageRead,
  //   exact: true,
  // },

  {
    path: '/admin/policy',
    loader: () =>
      import('src/view/policy/list/PolicyListPage'),
    permissionRequired: permissions.policyRead,
    exact: true,
  },
  {
    path: '/admin/policy/new',
    loader: () =>
      import('src/view/policy/form/PolicyFormPage'),
    permissionRequired: permissions.policyCreate,
    exact: true,
  },
  {
    path: '/admin/policy/importer',
    loader: () =>
      import(
        'src/view/policy/importer/PolicyImporterPage'
      ),
    permissionRequired: permissions.policyImport,
    exact: true,
  },
  {
    path: '/admin/policy/:id/edit',
    loader: () =>
      import('src/view/policy/form/PolicyFormPage'),
    permissionRequired: permissions.policyEdit,
    exact: true,
  },
  {
    path: '/policy',
    loader: () =>
      import('src/view/policy/list/PolicyListPage'),
    permissionRequired: permissions.policyRead,
    exact: true,
  },
  // { As of Dec 22 2022, I do not believe this is necessary anymore
  //  KEEPING UNTIL I HAVE TESTED
  //   // that copy is '/policy/:id'
  //   path: '/policy/:id',
  //   loader: () =>
  //     import('src/view/policy/view/PolicyViewPage'),
  //   permissionRequired: null,
  //   // permissionRequired: permissions.policyRead, default
  //   exact: true,
  // },

  {
    path: '/admin/vehicle-record',
    loader: () =>
      import('src/view/vehicleRecord/list/VehicleRecordListPage'),
    permissionRequired: permissions.vehicleRecordRead,
    exact: true,
  },
  {
    path: '/admin/vehicle-record/new',
    loader: () =>
      import('src/view/vehicleRecord/form/VehicleRecordFormPage'),
    permissionRequired: permissions.vehicleRecordCreate,
    exact: true,
  },
  {
    path: '/admin/vehicle-record/importer',
    loader: () =>
      import(
        'src/view/vehicleRecord/importer/VehicleRecordImporterPage'
      ),
    permissionRequired: permissions.vehicleRecordImport,
    exact: true,
  },
  {
    path: '/admin/vehicle-record/:id/edit',
    loader: () =>
      import('src/view/vehicleRecord/form/VehicleRecordFormPage'),
    permissionRequired: permissions.vehicleRecordEdit,
    exact: true,
  },
  {
    path: '/admin/vehicle-record/:id',
    loader: () =>
      import('src/view/vehicleRecord/view/VehicleRecordViewPage'),
    permissionRequired: permissions.vehicleRecordRead,
    exact: true,
  },

  { // NOT a default path
    path: '/admin/vehicle-record/list-view',
    loader: () =>
    //@ts-ignore
      import('src/view/vehicleRecord/list/VehicleRecordListView'),
    permissionRequired: permissions.vehicleRecordRead,
    exact: true,
  },

  {
    path: '/admin/year',
    loader: () =>
      import('src/view/year/list/YearListPage'),
    permissionRequired: permissions.yearRead,
    exact: true,
  },
  {
    path: '/admin/year/new',
    loader: () =>
      import('src/view/year/form/YearFormPage'),
    permissionRequired: permissions.yearCreate,
    exact: true,
  },
  {
    path: '/admin/year/importer',
    loader: () =>
      import(
        'src/view/year/importer/YearImporterPage'
      ),
    permissionRequired: permissions.yearImport,
    exact: true,
  },
  {
    path: '/admin/year/:id/edit',
    loader: () =>
      import('src/view/year/form/YearFormPage'),
    permissionRequired: permissions.yearEdit,
    exact: true,
  },
  {
    path: '/admin/year/:id',
    loader: () =>
      import('src/view/year/view/YearViewPage'),
    permissionRequired: permissions.yearRead,
    exact: true,
  },

  {
    path: '/admin/make',
    loader: () =>
      import('src/view/make/list/MakeListPage'),
    permissionRequired: permissions.makeRead,
    exact: true,
  },
  {
    path: '/admin/make/new',
    loader: () =>
      import('src/view/make/form/MakeFormPage'),
    permissionRequired: permissions.makeCreate,
    exact: true,
  },
  {
    path: '/admin/make/importer',
    loader: () =>
      import(
        'src/view/make/importer/MakeImporterPage'
      ),
    permissionRequired: permissions.makeImport,
    exact: true,
  },
  {
    path: '/admin/make/:id/edit',
    loader: () =>
      import('src/view/make/form/MakeFormPage'),
    permissionRequired: permissions.makeEdit,
    exact: true,
  },
  {
    path: '/admin/make/:id',
    loader: () =>
      import('src/view/make/view/MakeViewPage'),
    permissionRequired: permissions.makeRead,
    exact: true,
  },

  {
    path: '/admin/model',
    loader: () =>
      import('src/view/model/list/ModelListPage'),
    permissionRequired: permissions.modelRead,
    exact: true,
  },
  {
    path: '/admin/model/new',
    loader: () =>
      import('src/view/model/form/ModelFormPage'),
    permissionRequired: permissions.modelCreate,
    exact: true,
  },
  {
    path: '/admin/model/importer',
    loader: () =>
      import(
        'src/view/model/importer/ModelImporterPage'
      ),
    permissionRequired: permissions.modelImport,
    exact: true,
  },
  {
    path: '/admin/model/:id/edit',
    loader: () =>
      import('src/view/model/form/ModelFormPage'),
    permissionRequired: permissions.modelEdit,
    exact: true,
  },
  {
    path: '/admin/model/:id',
    loader: () =>
      import('src/view/model/view/ModelViewPage'),
    permissionRequired: permissions.modelRead,
    exact: true,
  },

  {
    path: '/admin/trim',
    loader: () =>
      import('src/view/trim/list/TrimListPage'),
    permissionRequired: permissions.trimRead,
    exact: true,
  },
  {
    path: '/admin/trim/new',
    loader: () =>
      import('src/view/trim/form/TrimFormPage'),
    permissionRequired: permissions.trimCreate,
    exact: true,
  },
  {
    path: '/admin/trim/importer',
    loader: () =>
      import(
        'src/view/trim/importer/TrimImporterPage'
      ),
    permissionRequired: permissions.trimImport,
    exact: true,
  },
  {
    path: '/admin/trim/:id/edit',
    loader: () =>
      import('src/view/trim/form/TrimFormPage'),
    permissionRequired: permissions.trimEdit,
    exact: true,
  },
  {
    path: '/admin/trim/:id',
    loader: () =>
      import('src/view/trim/view/TrimViewPage'),
    permissionRequired: permissions.trimRead,
    exact: true,
  },

  {
    path: '/admin/engine',
    loader: () =>
      import('src/view/engine/list/EngineListPage'),
    permissionRequired: permissions.engineRead,
    exact: true,
  },
  {
    path: '/admin/engine/new',
    loader: () =>
      import('src/view/engine/form/EngineFormPage'),
    permissionRequired: permissions.engineCreate,
    exact: true,
  },
  {
    path: '/admin/engine/importer',
    loader: () =>
      import(
        'src/view/engine/importer/EngineImporterPage'
      ),
    permissionRequired: permissions.engineImport,
    exact: true,
  },
  {
    path: '/admin/engine/:id/edit',
    loader: () =>
      import('src/view/engine/form/EngineFormPage'),
    permissionRequired: permissions.engineEdit,
    exact: true,
  },
  {
    path: '/admin/engine/:id',
    loader: () =>
      import('src/view/engine/view/EngineViewPage'),
    permissionRequired: permissions.engineRead,
    exact: true,
  },

  

  {
    path: '/profile/search-history',
    loader: () =>
      import('src/view/searchHistory/list/SearchHistoryListPage'),
    permissionRequired: permissions.searchHistoryRead,
    exact: true,
  },
  {
    path: '/profile/search-history/new',
    loader: () =>
      import('src/view/searchHistory/form/SearchHistoryFormPage'),
    permissionRequired: permissions.searchHistoryCreate,
    exact: true,
  },
  {
    path: '/profile/search-history/importer',
    loader: () =>
      import(
        'src/view/searchHistory/importer/SearchHistoryImporterPage'
      ),
    permissionRequired: permissions.searchHistoryImport,
    exact: true,
  },
  {
    path: '/profile/search-history/:id/edit',
    loader: () =>
      import('src/view/searchHistory/form/SearchHistoryFormPage'),
    permissionRequired: permissions.searchHistoryEdit,
    exact: true,
  },
  {
    path: '/profile/search-history/:id',
    loader: () =>
      import('src/view/searchHistory/view/SearchHistoryViewPage'),
    permissionRequired: permissions.searchHistoryRead,
    exact: true,
  },

  
].filter(Boolean);

export default privateRoutes;