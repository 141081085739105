import list from 'src/modules/make/list/makeListReducers';
import form from 'src/modules/make/form/makeFormReducers';
import view from 'src/modules/make/view/makeViewReducers';
import destroy from 'src/modules/make/destroy/makeDestroyReducers';
import importerReducer from 'src/modules/make/importer/makeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
