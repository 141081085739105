/**
 * For now using this default search config
 * May explore alt ways of implementation later[localhost|production tsx]
 */

export const APP = 'web_news_0,web_products_parts_0,ymme,queries'; // web_news_articles_0, news,parts, ,parts, ymme | causing an error - no parts or ymme index????
// export const CRED = '361a597567f5:6cd7fa44-d671-4eb6-a663-2fdb6eb6c7aa';
export const URL = 'https://361a597567f5:6cd7fa44-d671-4eb6-a663-2fdb6eb6c7aa@askautoace-ttgooam-arc.searchbase.io'; // @askautoace-ttgooam-arc.searchbase.io
// export const APPBASECONFIG = { v3.x.x
export const REACTIVESEARCHAPICONFIG = { // v4.x.x
    recordAnalytics: false, 
    searchStateHeader: false, 
    emptyQuery: true, 
    enableQueryRules: false, 
    suggestionAnalytics: false, 
    userId: '', 
    customEvents: {}
}