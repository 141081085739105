import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'make',
    label: i18n('entities.make.fields.make'),
    schema: schemas.string(
      i18n('entities.make.fields.make'),
      {
        "min": 2,
        "max": 55
      },
    ),
  },
];