import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/make/importer/makeImporterSelectors';
import MakeService from 'src/modules/make/makeService';
import fields from 'src/modules/make/importer/makeImporterFields';
import { i18n } from 'src/i18n';

const makeImporterActions = importerActions(
  'MAKE_IMPORTER',
  selectors,
  MakeService.import,
  fields,
  i18n('entities.make.importer.fileName'),
);

export default makeImporterActions;