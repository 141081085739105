import list from 'src/modules/vehicleRecord/list/vehicleRecordListReducers';
import form from 'src/modules/vehicleRecord/form/vehicleRecordFormReducers';
import view from 'src/modules/vehicleRecord/view/vehicleRecordViewReducers';
import destroy from 'src/modules/vehicleRecord/destroy/vehicleRecordDestroyReducers';
import importerReducer from 'src/modules/vehicleRecord/importer/vehicleRecordImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
