/**
 * NOT a default file
 * 
 * modeled after ContentWrapper
 * 
 * used to replace the root div with makeStyles css
 * in most Layout* files, for mui v5x migration
 * 
 */

import { styled } from '@mui/material/styles';

// 
const RootWrapper = styled('div')({
  color: 'rgba(0, 0, 0, 0.65)',
  backgroundColor: '#f0f2f5', // default: f0f2f5 | f5f5f5, fff
  display: 'flex',
  fontFamily: `'Roboto', sans-serif`,
  '& h1, h2, h3, h4, h5, h6': {
    color: 'rgba(0, 0, 0, 0.85)',
  },
  // added root styles below
  flexDirection: 'column', // ***KEEP***, necessary for proper page/footer layout flow
});

export default RootWrapper;
