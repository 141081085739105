import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.page.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.page.fields.name'),
  },
  // bof added slug [Andre]
  // bof added for huge refactor process
  { 
    name: 'slug',
    label: i18n('entities.page.fields.slug'),
  },
  // bof added slug [Andre]
  {
    name: 'headingOne',
    label: i18n('entities.page.fields.headingOne'),
  },
  {
    name: 'imageOne',
    label: i18n('entities.page.fields.imageOne'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'markeingTextOne',
    label: i18n('entities.page.fields.markeingTextOne'),
  },
  {
    name: 'headingTwo',
    label: i18n('entities.page.fields.headingTwo'),
  },
  {
    name: 'imageTwo',
    label: i18n('entities.page.fields.imageTwo'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'marketingTextTwo',
    label: i18n('entities.page.fields.marketingTextTwo'),
  },
  {
    name: 'headingThree',
    label: i18n('entities.page.fields.headingThree'),
  },
  {
    name: 'imageThree',
    label: i18n('entities.page.fields.imageThree'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'marketingTextThree',
    label: i18n('entities.page.fields.marketingTextThree'),
  },
  {
    name: 'headingFour',
    label: i18n('entities.page.fields.headingFour'),
  },
  {
    name: 'imageFour',
    label: i18n('entities.page.fields.imageFour'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'marketingTextFour',
    label: i18n('entities.page.fields.marketingTextFour'),
  },
  {
    name: 'headingFive',
    label: i18n('entities.page.fields.headingFive'),
  },
  {
    name: 'imageFive',
    label: i18n('entities.page.fields.imageFive'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'marketingTextFive',
    label: i18n('entities.page.fields.marketingTextFive'),
  },
  // eof for huge refactor process
  {
    name: 'pageBody',
    label: i18n('entities.page.fields.pageBody'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.page.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.page.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
