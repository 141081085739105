import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'trim',
    label: i18n('entities.trim.fields.trim'),
    schema: schemas.string(
      i18n('entities.trim.fields.trim'),
      {
        "min": 1,
        "max": 55
      },
    ),
  },
];