import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/vehicleRecord/importer/vehicleRecordImporterSelectors';
import VehicleRecordService from 'src/modules/vehicleRecord/vehicleRecordService';
import fields from 'src/modules/vehicleRecord/importer/vehicleRecordImporterFields';
import { i18n } from 'src/i18n';

const vehicleRecordImporterActions = importerActions(
  'VEHICLERECORD_IMPORTER',
  selectors,
  VehicleRecordService.import,
  fields,
  i18n('entities.vehicleRecord.importer.fileName'),
);

export default vehicleRecordImporterActions;
