import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.policy.fields.name'),
    schema: schemas.string(
      i18n('entities.policy.fields.name'),
      {
        "required": true,
        "max": 35
      },
    ),
  },
  // bof added slug [Andre]
  {
    name: 'slug',
    label: i18n('entities.policy.fields.slug'),
    schema: schemas.string(
      i18n('entities.policy.fields.slug'),
      {
        "required": true,
        "max": 100
      },
    ),
  },
  // eof added slug [Andre]
  {
    name: 'heading',
    label: i18n('entities.policy.fields.heading'),
    schema: schemas.string(
      i18n('entities.policy.fields.heading'),
      {
        "max": 35
      },
    ),
  },
  {
    name: 'policyBody',
    label: i18n('entities.policy.fields.policyBody'),
    schema: schemas.string(
      i18n('entities.policy.fields.policyBody'),
      {
        "max": 85000
      },
    ),
  },
];