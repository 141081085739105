// Place the URL here with the /api suffix.
// Ex.:`https://domain.com/api`; default: /api
const backendUrl = `https://web-jp5no.ondigitalocean.app/api`;

// SwaggerUI Documentation URL
// Leave black if documentation should be hidden, leaving blank in production
const apiDocumentationUrl = ``; // /documentation

/**
 * Frontend URL.
 */
const frontendUrl = {
  host: 'web-jp5no.ondigitalocean.app', // default: domain.com
  protocol: 'https',
};
console.log('frontendUrl is: ', frontendUrl);

/**
 * Tenant Mode
 * multi: Allow new users to create new tenants.
 * multi-with-subdomain: Same as multi, but enable access to the tenant via subdomain.
 * single: One tenant, the first user to register will be the admin.
 */
const tenantMode = 'single';

/**
 * Plan payments configuration.
 */
const isPlanEnabled = false; // true
const stripePublishableKey = '';

/**
 * These are added env vars to help with Menu permissions
 * until I figure out a better way
 * 
 * false hides the Entity from users
 */
 const onlyAdminEnabled = true;
 const isBusinessEnabled = false;
 

export default {
  frontendUrl,
  backendUrl,
  apiDocumentationUrl,
  tenantMode,
  isPlanEnabled,
  stripePublishableKey,
  onlyAdminEnabled,
  isBusinessEnabled,
};
