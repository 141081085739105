/**
 * 
 * ### publicRoute has been renamed searchRoute ###
 * 
 * ### MAJOR REFACTOR ###
 * Repurposed, now serves as the public view for NON auth'd users 
 * Is NO LONGER used for auth view routes, for that see AuthRoute
 * ### IMPORTANT ###
 *  As of Dec 22 2022, THIS has replaced VisitorRoutes.tsx and 
 *  visitorRoutes array in routes.tsx
 *  It works in conjunction with Felipe's other VisitorRoutes code
 *  one less array and HOC to deal with
 * ### IMPORTANT ###
 * 
 */

import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom'; // Redirect was included by default

// added pkgs
// SearchLayout replaces Layout
import SearchLayout from 'src/view/layout/SearchLayout';
import queryString from 'query-string';

function SearchRoute({
  component: Component,
  ...rest
}) {
  const location = useLocation();

  console.log('using PublicRoute: route before return is: ', location.pathname);
  // *** THIS REDIRECT CODE BEFORE THE RETURN CALL WORKS ***
  const query = queryString.parse(location.search); // THIS WORKS!!!
  //@ts-ignore
  console.log('query before return is: ', query.q); // THIS WORKS!!! returns a string value

  if (location.pathname === "/search" && query.q === undefined) {
    return <Redirect to="/webhp" />
  }

  if (location.pathname === "/vhclsrp" && query.q === undefined) {
    return <Redirect to="/webhp" />
  }

  if (location.pathname === "/prtsrp" && query.q === undefined) {
    return <Redirect to="/webhp" />
  }

  if (location.pathname === "/nwsrp" && query.q === undefined) {
    return <Redirect to="/webhp" />
  }

  if (location.pathname === "/rcngrp" && query.q === undefined) {
    return <Redirect to="/webhp" />
  }

  return (
    <Route
      {...rest}
      render={(props) => {

        // NOT DEFAULT
        // if (!permissionChecker.isAuthenticated && !permissionChecker.isAdmin) {
        //   return <Redirect to="/about" />
        // }
        // NOT DEFAULT

        return ( // Layout {...props} is NOT default
          <SearchLayout>
            <Component {...props} />
          </SearchLayout>
        )
      }}
    />
  );
}

export default SearchRoute;