import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/model/importer/modelImporterSelectors';
import ModelService from 'src/modules/model/modelService';
import fields from 'src/modules/model/importer/modelImporterFields';
import { i18n } from 'src/i18n';

const modelImporterActions = importerActions(
  'MODEL_IMPORTER',
  selectors,
  ModelService.import,
  fields,
  i18n('entities.model.importer.fileName'),
);

export default modelImporterActions;