/**
 * NOT a default file
 * Largely carryover from SHv5
 * Uses React and MU Hooks(for styling)
 * Refactored much of the markup and css
 *  deleted unused css rules
 *  deleted any unused attributes of MU elements
 *  Now, much more concise
 * Added borderRadius to img css
 *  Need to figure a way to add a second logo in settings for the footer
 * Finally fixed the unique key error, made one key footerText and the other footerLink, much like ResultsFooter
 * https://css-tricks.com/almanac/properties/f/flex/
 *  flex: '1 1 auto', // 1 1 auto
 * 
 */

import { Link } from 'react-router-dom';
import { Box, List, ListItem, Toolbar, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles'; // mui v5x migration
import { Copyright } from '@mui/icons-material';
import { i18n } from 'src/i18n';

// added pkgs
import blueFontLogo from 'src/static/images/blueFontLogo.jpeg';

// const useStyles = makeStyles((theme) => ({
//   logo: {
//     paddingLeft: theme.spacing(0),
//     color: theme.palette.getContrastText(
//       theme.palette.primary.main,
//     ),
//     textDecoration: 'none',
//   },
// }));

function C() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
    >
      <span>
        <Copyright
          style={{
            color: 'rgba(0,0,0, 0.34)',
            margin: '-5px',
            marginRight: '5px',
          }}
        />
        {i18n('app.title')} {'   '}
        {new Date().getFullYear()} All Rights Reserved
        {'.'}
      </span>
    </Typography>
  );
}

const footerLinks = [
  {
    footerText: 'About',
    footerUrl: ['/page/about'], // slug liking works
    // /about, /page/:slug, /page/:id dont work
  },
  {
    footerText: 'FAQ',
    footerUrl: ['/faq'],
  },
  {
    footerText: 'Team',
    footerUrl: ['/about#team'],
  },
  {
    footerText: 'Terms',
    footerUrl: ['/policy/terms'], // slug linking works
    // footerUrl: ['/policy/a439ae43-f25b-4a34-bda8-d20810d35422'], default linking
  },
  {
    footerText: 'Privacy',
    footerUrl: ['/policy/privacy'], // slug linking
    // footerUrl: ['/policy/d8d4b1af-9242-4ba4-b54b-e8b469794bb0'], default linking
  },
]

function Footer() {
  // const classes = useStyles();

  // const whiteFontLogo = useSelector(selectors.selectLogoUrl);

  return (
    <>
    <Toolbar>
      <Box ml={20} display="flex" justifyContent="left">
        <Box sx={{ pl: 0, color: "white", textDecoration: "none" }}>
        <Link to="/webhp"> {/*  className={classes.logo} */}
          {blueFontLogo ? (
            <img
              src={blueFontLogo}
              width="164px"
              alt={i18n('app.title')}
              style={{verticalAlign: 'middle', borderRadius: '4px'}}
            />
          ) : (
            <>{i18n('app.title')}</>  
          )}
      </Link>
      </Box>
    </Box>

      <Box mt={1} ml={5}>
        <List style={{ display: 'flex', flexDirection: 'row'}}>
          {footerLinks.map((footerLink) => (
            <ListItem key={footerLink.footerText}>
              <Link
                to={`${footerLink.footerUrl}`}
                // target="_blank" rel="noopener noreferrer"
                style={{textDecoration: 'none'}}
              >
                {footerLink.footerText}
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box flexGrow={1}></Box>
        <Box mr={20} pt={1}>
          <C />
        </Box>
      </Toolbar>
  </>
  )
}

export default Footer;