/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {




      userVehiclePhotos: {
        id: 'userVehiclePhotos',
        folder: 'tenant/:tenantId/userVehicle/photos',
        maxSizeInBytes: 1000000,
      },



















      businessImage: {
        id: 'businessImage',
        folder: 'tenant/:tenantId/business/image',
        maxSizeInBytes: 1000000,
      },





      employeePhoto: {
        id: 'employeePhoto',
        folder: 'tenant/:tenantId/employee/photo',
        maxSizeInBytes: 1000000,
      },








      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      settingsBackgroundImages: {
        id: 'settingsBackgroundImages',
        folder:
          'tenant/:tenantId/settings/backgroundImages',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
    };
  }
}
